<template>
  <v-dialog
      max-width="600px"
      v-model="dialog"
      @input="get_branches"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">Edit</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="username" name="User Name" rules="required" v-slot="{ errors }">
                  <v-text-field
                      label="User Name*"
                      hint="User Name"
                      required
                      autocomplete="nope"
                      v-model="user.username"
                      maxlength="20"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="first_name" name="First name" rules="required"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="First Name*"
                      hint="First Name"
                      required
                      autocomplete="nope"
                      v-model="user.first_name"
                      maxlength="256"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="last_name" name="Last name" rules="required"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Last Name*"
                      hint="Last Name"
                      required
                      autocomplete="nope"
                      v-model="user.last_name"
                      maxlength="256"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="group" name="Groups" rules="objectNotEmpty:id|required"
                                    v-slot="{ errors }">
                  <v-select label="Select a Group*" v-model="user.group" hint="User's Group"
                            :items="group" item-text="name" clearable
                            item-value="id" item-color="primary">

                  </v-select>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="branch" name="Branch name" rules="objectNotEmpty:code|required"
                                    v-slot="{ errors }">
                  <v-select label="Select a branch*" v-model="user.branch" hint="User's Branch"
                            :items="branch" item-text="name" clearable
                            item-value="id" item-color="primary">
                    <template v-slot:prepend-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-text-field v-model="search" placeholder="Search"
                                        @input="searchBranch"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"

export default {
  name: "add_edit_form",
  props: ['passed_item', 'icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    user: {
      id: '',
      username: '',
      first_name: '',
      last_name: '',
      last_login: '',
      group: {
        name: '',
        id: ''
      },
      date_joined: '',
      branch: {
        name: '',
        code: '',
        id: ''
      },
    },
    search: "",
    branch: [{name: 'test', code: 'test', id: 'test'}],
    branchCopy: [],
    branch_loading: false,
    group: [{name: '', id: ''}],
    group_loading: false,
  }),
  methods: {
    async add_new_user() {
      this.axios_conf
      let data = {
        username: this.user.username,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        branch: this.user.branch,
        group: this.user.group,
      }
      // console.log(this.zone.circle)
      await axios.post('user/', data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.$refs.vform.reset()
            this.$refs.vform.resetValidation()
            this.dialog = false
            this.$emit('form_status', true)
            this.$emit('snackbar_st', 'New User Added')
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                username: error.response.data.errors.username,
                first_name: error.response.data.errors.first_name,
                last_name: error.response.data.errors.last_name,
                branch: error.response.data.errors.branch,
                group: error.response.data.errors.group,
              });
            }
          })
      this.submit_loading = false
    },
    async edit_user() {
      this.axios_conf
      // console.log(this.zone)
      let data = {
        username: this.user.username,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        branch: this.user.branch,
        group: this.user.group,
      }
      if (this.user.branch.id) {
        data.branch = this.user.branch.id
      }
      if (this.user.group.id) {
        data.group = this.user.group.id
      }
      await axios.patch(`user/${this.user.id}/update`, data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$emit('form_status', true)
            this.$emit('snackbar_st', `User#${this.user.username} Updated`)
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                username: error.response.data.errors.username,
                first_name: error.response.data.errors.first_name,
                last_name: error.response.data.errors.last_name,
                branch: error.response.data.errors.branch,
                group: error.response.data.errors.group,
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      if (this.action === 'add') {
        this.add_new_user()
      } else if (this.action === 'edit') {
        this.edit_user()
      }
    },
    async get_branches() {
      this.axios_conf
      this.group_loading = true
      await axios.get("user/groups")
          .then((resp) => {
            this.group = resp.data.groups
            this.$store.commit('setJWT', resp.data.key)
          })
      this.group_loading = false
      this.branch_loading = true
      this.axios_conf
      await axios.get("user/branch_office")
          .then((resp) => {
            this.branch = resp.data.branches
            this.branchCopy = [...this.branch]
            this.$store.commit('setJWT', resp.data.key)
          })
      this.branch_loading = false
    },
    searchBranch() {
      if (!this.search) {
        this.branch = this.branchCopy;
      }
      this.branch = this.branchCopy.filter((br) => {
        return br.code.toString() === this.search || br.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },
  watch: {
    // passed_item(val) {
    //   // console.log(val)
    //   this.branch = JSON.parse(JSON.stringify(val))
    //   // console.log(this.zone)
    // },
    // zone(val) {
    //   console.log(val)
    // }
  },
  mounted() {
    if (this.passed_item) {
      this.user = JSON.parse(JSON.stringify(this.passed_item))
    }
  }
}
</script>

<style scoped>
</style>