<template>
  <v-container>
    <user-list-table/>
  </v-container>
</template>

<script>
import UserListTable from "@/components/user_components/UserListTable";

export default {
  name: "UserListPage",
  components: {
    UserListTable,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Personal'

  }
}
</script>

<style scoped>

</style>