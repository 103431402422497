<template>
  <v-container v-if="has_view_permission">
    <!--    <v-snackbar-->
    <!--        :timeout="2000"-->
    <!--        v-model="show_snackbar"-->
    <!--        absolute-->
    <!--        centered-->
    <!--        shaped-->
    <!--        top-->
    <!--        color="success"-->
    <!--    >-->
    <!--      {{ snackbar_text }}-->
    <!--      <template v-slot:action="{ attrs }">-->
    <!--        <v-btn-->
    <!--            color="white"-->
    <!--            text-->
    <!--            v-bind="attrs"-->
    <!--            @click="show_snackbar = false"-->
    <!--        >-->
    <!--          Close-->
    <!--        </v-btn>-->
    <!--      </template>-->
    <!--    </v-snackbar>-->
    <v-card class="my-1 pa-3 blue accent-1">
      <v-card-title>
        <add_edit_form v-if="add_button" activator_name="Add New User"
                       form_title="Add New User"
                       activator_icon="mdi-plus-thick" action="add" @form_status="get_users"
                       big_btn="true" @snackbar_st="snackbars"></add_edit_form>
        <v-btn :loading="reload_btn" @click="get_users" class="ma-1">
          <v-icon>
            mdi-reload
          </v-icon>
          Reload Data Table
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card class="yellow lighten-2">
      <v-data-table
          :headers="column_headers"
          :items="table_items"
          sort-by="username"
          class="elevation-5 yellow lighten-2"
          :loading="table_loading"
          :search="search"
          loading-text="Loading... Please wait"
          item-key="id"
          name="user_table"
      >
        <template v-slot:top>
          <v-card-title
              flat
          >
            User List
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
        </template>
        <!--        <template v-slot:item.cause="{item}">-->

        <!--          <span style="overflow-wrap: anywhere;">{{ item.cause }}</span>-->

        <!--        </template>-->
        <!--        <template v-slot:item="{item}">-->
        <!--          <tr>-->
        <!--            <td class="text-center">-->
        <!--              {{ item.username }}-->
        <!--            </td>-->
        <!--            <td class="text-center">-->
        <!--              {{ item.first_name }}-->
        <!--            </td>-->
        <!--            <td class="text-center">-->
        <!--              {{ item.last_name }}-->
        <!--            </td>-->
        <!--            <td class="text-center">-->
        <!--              {{ item.group.name }}-->
        <!--            </td>-->
        <!--            <td class="text-center">-->
        <!--              {{ item.branch.name }}-->
        <!--            </td>-->
        <!--            <td class="text-center">-->
        <!--              {{ item.last_login }}-->
        <!--            </td>-->
        <!--            <td class="text-center">-->
        <!--              <add_edit_form v-if="edit_button"-->
        <!--                             :form_title="`Edit User# ${item.username}`" :passed_item="item"-->
        <!--                             activator_icon="mdi-pencil" action="edit" @form_status="get_users" @snackbar_st="snackbars"-->
        <!--              ></add_edit_form>-->
        <!--              <delete_dialog v-if="delete_button" @form_status="get_users" :passed_item="item"-->
        <!--                             :dialogue_text="`Are you sure you want to delete user# ${item.username}?`"-->
        <!--                             :url="`user/${item.id}/delete`"-->
        <!--                             icon="mdi-delete" tooltip_text="Delete" @snackbar_st="snackbars"-->
        <!--                             action="delete"></delete_dialog>-->
        <!--              <delete_dialog v-if="reset_button" @form_status="get_users" :passed_item="item"-->
        <!--                             icon="mdi-restart" tooltip_text="Reset" @snackbar_st="snackbars"-->
        <!--                             :dialogue_text="`Are you sure you want to Reset Password for user#${item.username}`"-->
        <!--                             :url="`user/${item.id}/reset`" action="post"></delete_dialog>-->
        <!--            </td>-->
        <!--          </tr>-->
        <!--        </template>-->
        <template v-slot:item.actions="{ item }">
          <add_edit_form v-if="edit_button"
                         :form_title="`Edit User# ${item.username}`" :passed_item="item"
                         activator_icon="mdi-pencil" action="edit" @form_status="get_users" @snackbar_st="snackbars"
          ></add_edit_form>
          <delete_dialog v-if="delete_button" @form_status="get_users" :passed_item="item"
                         :dialogue_text="`Are you sure you want to delete user# ${item.username}?`"
                         :url="`user/${item.id}/delete`"
                         icon="mdi-delete" tooltip_text="Delete" @snackbar_st="snackbars"
                         action="delete"></delete_dialog>
          <delete_dialog v-if="reset_button" @form_status="get_users" :passed_item="item"
                         icon="mdi-restart" tooltip_text="Reset" @snackbar_st="snackbars"
                         :dialogue_text="`Are you sure you want to Reset Password for user#${item.username}`"
                         :url="`user/${item.id}/reset`" action="post"></delete_dialog>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import add_edit_form from "@/components/user_components/user-sub_component/add_edit_form";
import delete_dialog from "@/components/user_components/user-sub_component/delete_dialog.vue";
import {user_urls} from "@/constants/user_urls";


export default {
  name: "UserListTable",
  components: {add_edit_form, delete_dialog},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('users.view_customuser')
    },
    edit_button() {
      return this.$store.getters.permissionCheckers('users.change_customuser')
    },
    delete_button() {
      return this.$store.getters.permissionCheckers('users.delete_customuser')
    },
    reset_button() {
      return this.$store.getters.permissionCheckers('users.reset_password')
    },
    add_button() {
      return this.$store.getters.permissionCheckers('users.add_customuser')
    },
  },
  data: () => ({
    reload_btn: false,
    table_loading: false,
    column_headers: [
      {
        text: 'User Name',
        align: 'center',
        filterable: true,
        value: 'username',
      },
      {
        text: 'First Name',
        align: 'center',
        filterable: true,
        value: 'first_name',
      },
      {
        text: 'Last Name',
        align: 'center',
        filterable: true,
        value: 'last_name',
      },
      {
        text: 'Group',
        align: 'center',
        filterable: true,
        value: 'group.name',
      },
      {
        text: 'Branch Name',
        align: 'center',
        filterable: true,
        value: 'branch.name',
      },
      {
        text: 'Last Login',
        align: 'center',
        filterable: true,
        value: 'last_login',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: false,
        value: 'actions',
      },
    ],
    table_items: [],
    search: '',
    show_snackbar: false,
    snackbar_text: null,
  }),
  methods: {
    async get_users() {
      this.axios_conf
      this.reload_btn = true
      this.table_loading = true
      await axios.get(user_urls.user.list).then((resp) => {
        // console.log(resp)
        this.table_items = resp.data.users
        this.$store.commit('setJWT', resp.data.key)
      })
      this.reload_btn = false
      this.table_loading = false
    },
    snackbars(value) {
      // console.log(value)
      this.snackbar_text = value
      this.show_snackbar = true
    }
  },
  mounted() {
    this.get_users();
  }
}
</script>

<style scoped>
tbody tr:hover {
  background: #82B1FF !important;
}
</style>